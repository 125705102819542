.expense-report-page-container {
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.expense-report-page-filters-container {
  padding: 15px 0 10px;
  display: flex;
  gap: 0;
  align-items: center;
  margin: 10px 0;
  flex-wrap: nowrap;
  width: 100%;
}

.expense-report-page-filters-container > * {
  flex: 1 1 auto;
  min-width: 150px;
  margin-right: 10px;
}

.expense-report-page-date-modal-container {
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  border: 1px solid #000;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;
}

.expense-report-page-date-modal-header-container {
  display: flex;
  justify-content: flex-end;
}

.expense-report-page-date-modal-chip-container {
  display: flex;
  gap: 5px;
  align-items: baseline;
  flex-wrap: wrap;
  justify-content: center;
}
