.telephone-page-telephone-expenses-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.telephone-page-telephone-expenses-top-table-container {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding: 0 10px;
  gap: 15px;
}

.telephone-page-telephone-expenses-massive-load-filters-modal-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.telephone-page-telephone-filters-container {
  padding: 15px 0 10px;
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 10px 0 10px;
  flex-wrap: nowrap;
  overflow: hidden;
  width: 100%;
}

.telephone-page-telephone-filters-container > * {
  flex-shrink: 1;
  min-width: 150px;
  max-width: 250px;
}

.telephone-page-telephone-filters-container-dynamic-columns {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}
